/**
 * File Name childTheme.js
 **/
// ######################################################################


/**
 * childTheme
 **/
var childTheme = {

	/**
	 * init
	 **/
	init : function() {

		this.changeLang();
		this.gmwSubmit();
		this.mobileMenu();
		// this.gaTrackEvents();

	}, // end init : function

	/**
	 * changeLang
	 **/
	changeLang : function() {

		jQuery('#select-lang').change(function() {
			var location = jQuery(':selected', this).val();
			window.location = location;
			return;
		});

	}, // end changeLang : function

	/**
	 * gmwSubmit
	 **/
	gmwSubmit : function() {

		jQuery('.gmw-form').submit(function(e) {


			var sForm  = jQuery(this);
			sForm.find('.gmw-paged').val('1');

			//get the entered address
			var address;
			if ( sForm.find('.gmw-address').hasClass('gmw-full-address') ) {
					address = sForm.find('.gmw-full-address').val();
			} else {
					address = [];
					sForm.find(".gmw-address").each(function() {
							address.push(jQuery(this).val());
					});
					address = address.join(' ');
			}

			if ( ! address ) {
				jQuery( '.gmw-address', sForm ).addClass('error');
			}

			// get the entered title
			var location_title = [];
			sForm.find("#gmw-location-name").each(function() {
					location_title.push(jQuery(this).val());
			});
			location_title = location_title.join(' ');

			// distance
			var distance = jQuery('input[name="gmw_distance"]').find(":selected").text();

			// services
			var services = jQuery('input[name="tax_tax-locations"]').find(":selected").text();

			/**
			 * Note: We are by-passing the search features to allow for post title search
			 * due to js being used to submit the form we need to insert our own url type submission
			 * user window.location to update the page because we can not allow the form submit to do it
			 **/

			// build search url
			if ( jQuery.trim( location_title ).length ) {
				var search = '?gmw_post=locations'
										+ '&tax_tax-locations=' + services
										+ '&gmw-location-name=' + location_title
										+ '&gmw_address[0]=' + address
										+ '&gmw_distance=' + distance
										+ '&gmw_form=1'
										+ '&gmw_per_page=5'
										// + '&gmw_lat=36.2130528'
										// + '&gmw_lng=-86.72654829999999'
										+ '&gmw_px=pt&action=gmw_post'
										+ '&gmw_px=pt'
										+ '&action=gmw_post'
										;
				window.location = siteObject.gmw_search_landing_page + search;

			}

		});

	}, // end gmwSubmit : function

	/**
	 * mobileMenu
	 **/
	mobileMenu : function() {

		jQuery('#menu-trigger').click(function(e) {
			jQuery('.avia-menu.av-main-nav-wrap').toggleClass('show');
		});

	}, // end example : function

	/**
	 * gaTrackEvent
	 **/
	gaTrackEvents : function() {
		if ( typeof __gaTracker == 'undefined' ) {
			return;
		}

		// outboound link
		jQuery('a.ga-event-make-appointment').click( function(event) {

			var track = {
		    eventCategory: 'Make Appointment',
		    eventAction: 'Button Click',
		    eventLabel: event.target.href
	  	};
			console.log(track);
			__gaTracker( 'send', 'event', track );

		} );

	} // gaTrackEvents : function

}; // end var childTheme


/**
 * jQuery
 **/
jQuery(document).ready(function() {
	childTheme.init();
});
